import { getPlatform } from '@/utils/util';
import { ShareData } from '@/typings/utils/share';
import axios from 'axios';

// 验证分享入口是否显示
export function show(): boolean {
  const { isApp } = getPlatform();
  return isApp;
}

// 小程序中的分享
export function miniProgram(data: ShareData): void {
  // 判断是否是小程序中（只有小程序中才处理）
  if (getPlatform().isMiniprogram) {
    (window as any).wx.default.miniProgram.postMessage({
      data
    });
  }
}

// 生成分享海报
export async function createPoster(params: any): Promise<string> {
  const r: any = await axios.post(`https://poster.gupaoedu.cn/api/link`, params);
  return r.url || '';
}
